import Box from '@mui/material/Box';
import Link from '@mui/material/Link'
import "./Home.css"
import { useState} from "react";
import { Button, TextField } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import iconUser from "../../images/User.png"
import iconPass from "../../images/Pass.png"
import axios from "axios";
import LoadingModal from '../widgets/LoadingModal';
import ErrorModal from '../widgets/ErrorModal';


export default function Home({ auth,isAuth }){
  const cards = [
    {
      "name":"Enlaces Internacionales",
      "link":"/pred_ra",
      "ini": "EI"
    }
    ,{
      "name":"Spread Guatemala",
      "link":"/spread_gtm",
      "ini":"SG"
    },
    {
      "name":"Mape Front",
      "link":"/mape_front",
      "ini":"MF"
    },
    {
      "name":"Mape Clientes/Cargas",
      "link":"/mape_clients",
      "ini":"MCC"
    },
    /*{
      "name":"Mape Clientes/Medidor",
      "link":"/mape_medidor",
      "ini":"MCM"
    },*/
    {
      "name":"Centro de información",
      "link":"/info_center",
      "ini":"IC"
    },
    {
      "name":"Pronostico Gens",
      "link":"/pron_gens",
      "ini":"PG" 
    },
    {
      "name":"Pronostico Front",
      "link":"/pron_front",
      "ini":"PF" 
    },
    {
      "name":"Mape Contraparte",
      "link":"/mape_con",
      "ini":"MC" 
    },
    {
      "name":"Nominacion",
      "link":"/nom_x_user",
      "ini":"NOM" 
    },
    {
      "name":"Cobertura Electrica",
      "link":"/cob_elect",
      "ini":"CE" 
    }
  ]

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [load, setLoad] = useState(false);
  
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  const url = `${process.env.REACT_APP_URL_BACK}`

  function Card({data}){
    return(
      <Box
      className="select">
          <div className='circle'>
            {data.ini}
          </div>
          <Link 
            className='link'
            href={data.link}
            underline="none"
          >
                {data.name}
          </Link>
      </Box>
    )
  }

  function login() {
    sessionStorage.clear()
    setLoad(true);
    const data = { username: user, password: pass };
    axios
      .post( url +"servicio/auth/", data)
      .then((response) => {
        sessionStorage.setItem("token", response.data.token);
        setError("");
        setLoad(false);
        isAuth(true)
      })
      .catch((e) => {
        sessionStorage.clear()
        setError(true);
        setLoad(false);
      });
  }

  if(auth){
    return(
      <>
          <Box className='body'>
              {cards.map((item,index) =>(
                <Card key={index} data={item}/>
              ))}
          </Box>
      </>
    );
  }else{
    return(
      <>
        <div>
          <LoadingModal isLoad={load}/>
        </div>
        <div className='body'>
          <div className='card'>
            <TextField
                className='field'
                id="standar-basic"
                label="Usuario"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={iconUser} alt="No"/>
                    </InputAdornment>
                  ),
                }}   
                onChange={(e) => setUser(e.target.value)}
              />

              <TextField
                className='field'
                id="input-with-icon-textfield"
                label="Contraseña"
                type="password"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={iconPass} alt="No"/>
                    </InputAdornment>
                  ),
                }}    
                onChange={(e) => setPass(e.target.value)}
              />

              <Button
                className="button"
                variant="contained"
                onClick={login}
                >
                Iniciar Sesión
              </Button>
            </div>
        </div>
        <ErrorModal open={error} close={handleClose}/>
      </>
      );
  }
}
