import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columnsGas = [
  { field: "clave", headerName: "Clave", width: 200 },
  { field: "adquiriente", headerName: "Adquiriente", width: 200 },
  { field: "emisor", headerName: "Emisor", width: 200 },
  { field: "fecha_registro", headerName: "Fecha Registro", width: 200 },
  { field: "fecha_firma", headerName: "Fecha Firma", width: 200 },
  { field: "fecha_fin", headerName: "Fecha Fin", width: 200 },
  { field: "fecha_inicio", headerName: "Fecha Inicio", width: 200 },
  { field: "tipo_contrato", headerName: "Tipo de Contrato", width: 500 },
];

export default function CoberturaElectrica({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  const [columns, setColumns] = useState([])
  const [values, setValues] = useState({
    dateI: dayjs(new Date() ),
    dateF: dayjs(new Date()),
    fecha_i: dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
    fecha_f: dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
  });
  const [data, setData] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;

  useEffect(() => {
  }, []);

  async function submit(){
    setLoad(true)
    setData([])
      const response = await post("servicio/cobertura_electrica/",values);
      setData(response.data)
      setColumns(columnsGas)
    setLoad(false)
    //setValues({})
  };

  const post = async(endpoint,info) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .post(url + endpoint, info, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  const get = async(endpoint) => {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .get(url + endpoint, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD")
    
    if(type == "fecha_i")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha_f")
      v['dateF'] = dayjs(event.$d.toISOString())
    
    setValues(v);
  }

  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }}>
        <FormControl style={formStyle}>
          <FormLabel>Fecha inicial:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_i")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha final:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateF}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_f")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl>
          <br/>
          <Button
            color="primary"
            variant="outlined"
            sx={{ height: "50px" }}
            onClick={submit}
          >
            Mostrar
          </Button>
        </FormControl>
      </form>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Centro de información</h1>
            </Box>

            <Form />
            <Table />
            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
