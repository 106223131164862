import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from "recharts";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columnsGas = [
  { field: "fecha", headerName: "Fecha", width: 200 },
  { field: "indice", headerName: "Indice", width: 200 },
  { field: "valor_gas", headerName: "Valor Gas", width: 200 },
  { field: "valor_gas_mo", headerName: "Valor Gas Mo", width: 200 },
  { field: "dif", headerName: "Diferencia", width: 200 },
  { field: "unidades", headerName: "Unidades", width: 200 },
];

const columnsTablePML = [
  { field: "fecha", headerName: "Fecha", width: 200},
  { field: "hora", headerName: "Hora", width: 200},
  { field: "clave_nodo", headerName: "Nodo", width: 200},
  { field: "pml", headerName: "PML", width: 200}
]

const columnsTablePND = [
  { field: "fecha", headerName: "Fecha", width: 200},
  { field: "hora", headerName: "Hora", width: 200},
  { field: "zona_carga", headerName: "Zona", width: 200},
  { field: "precio_zonal", headerName: "Precio Zonal", width: 200}
]

const columnsTableDolar = [
  { field: "fecha", headerName: "Fecha", width: 200},
  { field: "fix", headerName: "Fix", width: 200},
  { field: "dof", headerName: "Dof", width: 200},
  { field: "pagos", headerName: "Pagos", width: 200}
]

export default function InfoCenter({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const handleClose = () => setError(false);

  const nodos = [
    "PND-MDA (zonas)",
    "PND-MTR (zonas)",
    "PML-MDA (nodos)",
    "PML-MTR (nodos)",
    "Precios Del Gas",
    "Precios del dolar"
  ];

  const [columns, setColumns] = useState([])
  const [options, setOptions] = useState([])
  const [select, setSelect] = useState(false)
  const [graph_on, setGraphOn] = useState(false)
  const [values, setValues] = useState({
    info: "sel",
    dateI: dayjs(new Date() ),
    dateF: dayjs(new Date()),
    fecha_i: dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
    fecha_f: dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
    option: ""
  });
  const [data, setData] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;

  useEffect(() => {
  }, []);

  async function submit(){
    setLoad(true)
    setData([])
    if (values['info'] != undefined){
      if(values['info'].includes('Gas')){
        const response = await post("servicio/info_center/gas",values);
        setData(response.data)
        setColumns(columnsGas)
      }
      if(values['info'].includes('PND')){
        const response = await post("servicio/info_center/pnd",values);
        setData(response.data)
        setColumns(columnsTablePND)
      }
      if(values['info'].includes('PML')){
        const response = await post("servicio/info_center/pml",values);
        setData(response.data)
        setColumns(columnsTablePML)
      }
      if(values['info'].includes('dolar')){
        const response = await post("servicio/info_center/dolar",values);
        setData(response.data)
        setColumns(columnsTableDolar)
      }
    }
    setLoad(false)
    //setValues({})
  };

  async function getOption(event) {
    let v  =  values
    const opt = event.target.value
    if(opt.includes("Gas")){
      setLoad(true)
      const response = await get("servicio/info_center/gas");
      setSelect(true)
      setOptions(response.data)
      setLoad(false)
      v["option"] = "Waha"
      v["info"] = event.target.value
      v["fecha_i"] = dayjs().subtract(7,"days").format('YYYY-MM-DD')
      v["dateI"] = dayjs(new Date()).subtract(7,"days")
      setGraphOn(true)
    }
    else if(opt.includes("Gas")){
      setSelect(false)
      v["info"] = event.target.value
      setGraphOn(false)
    }
    else{
      setGraphOn(false)
      if(select){ v["option"] = ""} 
      setSelect(false)
      v["info"] = event.target.value
      v["tipo"] = event.target.value.substring(4,7) 
    }
    setValues(v)
    submit()
  }

  const post = async(endpoint,info) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .post(url + endpoint, info, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  const get = async(endpoint) => {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .get(url + endpoint, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  function onChange(event, type) {
    let v = values;
    v[type] = event.target.value.toUpperCase();

    setValues(v);
  }

  function onChange2(event, type) {
    let v = values;
    v[type] = event.target.value != -1 ?  event.target.value : "";

    setValues(v);
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD")
    
    if(type == "fecha_i")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha_f")
      v['dateF'] = dayjs(event.$d.toISOString())
    
    setValues(v);
  }

  function Graph() {
      return (
        <div>
          <ResponsiveContainer height={500}>
            <ComposedChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="fecha" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line name="Gas" type="monotone" dataKey="valor_gas" stroke="#0000ff" />
              <Line
                name="Gas Mo"
                type="monotone"
                dataKey="valor_gas_mo"
                stroke="#FF0000"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      );
    }

  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }}>
        <FormControl style={formStyle}>
          <FormLabel>Quiero información de:</FormLabel>
          <Select defaultValue={values.info} onChange={(e) => getOption(e)}>
            <MenuItem key={"sel"} value={"sel"}>
              Selecciona una opción
            </MenuItem>
            {nodos.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha inicial:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_i")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Fecha final:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateF}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha_f")}
            />
          </LocalizationProvider>
        </FormControl>
        
        {select ? 
          (<>
            <FormControl style={formStyle}>
              <FormLabel>Selección</FormLabel>
              <Select defaultValue={values.option} onChange={(e) => onChange2(e, "option")}>
                {options.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>)
        :
          (<>
            <FormControl style={formStyle}>
              <FormLabel>Nodo o Zona De Carga:</FormLabel>
              <TextField 
                defaultValue={values.option}
                variant="outlined" 
                onChange={(e) => onChange(e, "option")}
                inputProps={{style: {textTransform:'uppercase'}}}/>
            </FormControl>
          </>)
        }

        
        <FormControl>
          <br/>
          <Button
            color="primary"
            variant="outlined"
            sx={{ height: "50px" }}
            onClick={submit}
          >
            Mostrar
          </Button>
        </FormControl>
      </form>
    );
  }

  function Table() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Centro de información</h1>
            </Box>

            <Form />
            {graph_on ? (<Graph/>) : <></>}
            <Table />
            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
